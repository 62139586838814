<template>
  <v-sheet>

 
    <v-content class="mx-5">
      <ToolbarTitle
        @retroceder=" step==1 ? $router.go(-1) : step=1"
        :title=" step==1 ? 'Tipos documentales': 'Crear tipo documental' "
      />
    </v-content>
    <!-- Esto se puede hacer un componente -->
    <v-window v-model="step">
      <v-window-item :value="1">
       <v-row class="mx-15 mt-3">
        <v-col cols="12" sm="9">
          <v-text-field
            v-model="search"
            rounded
            dense
            filled
            label="Busca un tipo documental"
            prepend-icon="mdi-magnify"
          ></v-text-field>
          
        </v-col>
        <v-col>
          <v-btn @click="step=2" dark color="indigo darken-4">Crear tipo documental</v-btn>
        </v-col>
      </v-row>
        <v-row class="mx-auto" v-if="items.length != 0 && load == false">
          <v-col cols=6 sm="4" md="3" v-for="(documento, index) in itemsFilter" :key="index">
            <TiposDocumentales
              v-on:eliminar="eliminar(documento, index)"
              
              :nombre="documento.nombre"
              :parametros="documento.parametros"
              v-on:editar="edit(documento, index)"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="items.length == 0 && load">
          <Skeletons />
        </v-row>
        <v-row class="ma-5" v-else> No hay datos a mostrar </v-row>
      </v-window-item>
      <v-window-item :value="2">
        <v-sheet elevation="5" class="mt-6 mx-5">
          <v-row>
            <v-col>
                 <v-sheet
                  color="blue darken-4"
                  rounded=""
                  elevation=""
                  class="ma-2"
                  dark
                  
                >
                  <v-row>
                    <v-col>
                      <div
                        v-text="
                          boton_crear == true
                            ? 'Nuevo documento'
                            : 'Editar documento'
                        "
                        class="headline font-weight-light ml-2 mt-1"
                      ></div>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="3">
                      <v-tooltip  v-if="boton_crear == true" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                            fab
                            small
                            @click="crear()"
                            :disabled="!valid || $store.state.meta_data_store.length==0"
                          >
                            <v-icon color="indigo">
                              mdi-book-plus-multiple-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Crear</span>
                      </v-tooltip>
                      <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            small
                            color="white"
                            @click="editar()"
                            :disabled="!valid || $store.state.meta_data_store.length==0"
                          >
                            <v-icon color="green"> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-form ref="form" v-model="valid">
                <v-row class="mx-5">
                    <v-btn
                        class="mt-n8 mb-3"
                        @click="
                            boton_crear = true;
                            $refs.form.resetValidation();
                            nombre_documento = '';
                            fecha_limite= null;
                            $store.state.meta_data_store = [{id:Date.parse(new Date())+Math.random() , name: '', type: 'string', key: '', value: '' }];
                        "
                        v-if="boton_crear == false"
                        block
                        text
                        color="red"
                    >
                      Cancelar <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-text-field
                      :rules="[
                          (v) =>
                          !!v || 'Es requerido asignar un nombre al documento',
                      ]"
                      required
                      v-model="nombre_documento"
                      rounded
                      dense
                      filled
                      prepend-icon="mdi-book"
                      label="Nombre documento"
                    ></v-text-field>
                    <v-text-field 
                        filled rounded dense label="Dias habiles de respuesta " 
                        type="number" 
                        prepend-icon="mdi-calendar"
                        v-model="fecha_limite"
                    >

                    </v-text-field>
                </v-row>
                <v-divider></v-divider>
                <v-sheet>
                  <v-row>
                    <v-col cols="4" v-for="(data, index) in $store.state.meta_data_store" :key="data.id">
                        <FormDinamico :index=index />
                    </v-col>
                    <v-col class="d-flex justify-center" cols="4">
                      <v-card height="100" width="100" @click="$store.state.meta_data_store.push({ id: Date.parse(new Date())+Math.random(), name: '', type: 'string', key: '', value: '' })" >
                        
                        <v-btn color="indigo" dark class="ml-5" fab> <v-icon>mdi-plus</v-icon> </v-btn>
                        <v-card-text class="ml-2" >Agregar</v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-sheet>
                
              </v-form>
            </v-col>
          </v-row>
        </v-sheet>
      </v-window-item>
    </v-window>
    <v-dialog v-model="dialogEliminar" max-width="400">
      <v-card>
        <v-card-text>
          <h3 class="my-5" style="text-align: center; color: red">
            Proceso de eliminacion
          </h3>
          <v-divider></v-divider>
          <p>Estas seguro que desear eliminar el tipo documental:</p>
          <v-divider></v-divider>
          <h4 style="text-align: center; color: red">
            {{ nombre_documento_eliminar }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogEliminar = false" text color="red">Cerrar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="eliminacion_confirmada" text color="indigo"
            >Aceptar <v-icon class="mx-3">mdi-delete</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogo_expandir_creacion" fullscreen>
      <v-card>
        <v-toolbar dark color="indigo darken-4">
          <v-toolbar-title>Ingresa los campos del tipo documental</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogo_expandir_creacion=false" icon> <v-icon>mdi-close</v-icon> </v-btn>
        </v-toolbar>
        <v-card-text>

        </v-card-text>
      </v-card>
    </v-dialog>
   </v-sheet>
  
</template>
<script>
const axios = require("axios").default;
import ToolbarTitle from "../../components/admin/ToolbarTitle";
import TiposDocumentales from "../../components/admin/TiposDocumentales";
import Skeletons from "../../components/admin/Skeletons";
import buscador from "../../mixins/buscador";
import FormDinamico from '../../components/formularios/PruebasForm'
import { mapState } from 'vuex'
//import NuevoTipoDocumental from '../../components/admin/NuevoTipoDocumental'
export default {
  components: {
    ToolbarTitle,
    TiposDocumentales,
    Skeletons,
    FormDinamico,
    //NuevoTipoDocumental,
  },
  mixins: [buscador],
  data() {
    return {
      load: false,
      img: require("../../assets/documentos.png"),
     
      fecha_limite: null,
      menu: false,
      valid: true,
      //items: [], Es una variable que esta en el mixin del buscador que contiene todos los tipos documentales
      //search: null Variable que esta en el mixin por la cual se buscaran los tipos documentales
      //itemsFilter: [] Es una variable que esta en el mixin del buscador que contiene todos los  documentales filtrados
      objectId: "",
      boton_crear: true,
      index: -1,
      
      step : 1,

      dialogEliminar: false,
      nombre_documento_eliminar: "",
      nombre_documento: "",
      //meta_data: [{id:Date.parse(new Date()) , name: '', type: 'string', key: '', value: '' }],
      cols_crear: 3,
      cols_crear_sm: 5,
      cols_listar_sm: 7,
      cols_listar: 8,
      dialogo_expandir_creacion: false,

      //---------El scroll-----------//
      type: "number",
      number: 100,
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
      //easings: Object.keys(easings),
      //---------------------------//
    };
  },
  watch: {
    meta_data_store(){
      console.log(this.meta_data_store)
      if(this.$store.state.meta_data_store.length>5){
        this.cols_crear = 12
        this.cols_listar = 12
        this.cols_crear_sm = 12
        this.cols_listar_sm = 12
      }else{
        this.cols_crear_sm = 4
        this.cols_crear = 3
        this.cols_listar = 8
        this.cols_listar_sm = 7 
      }
    }
  },
  computed: {
    //Las computadas son del scroll
    ...mapState([
      'meta_data_store'
    ]),
    target() {
      const value = this[this.type];
      if (!isNaN(value)) return Number(value);
      else return value;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },

  async created() {
    console.log( Date.parse(new Date()) )
    this.load = true;
    try {
      this.$store.commit("auth", "parse");
      let response = await axios.get(
        "https://siett.educandote.co/parse/classes/V1_tiposDocumentales",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
            "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
            Authorization: this.$store.state.header,
          },
        }
      );
      console.log(response);
      if (typeof response.data != "undefined") {
        this.items = response.data.results;
        this.itemsFilter = response.data.results;
      }
    } catch (error) {
      console.log(error);
      this.$store.commit(
        "error",
        "Algo ha salido mal consultando los tipos documentales, por favor intente mas tarde "+error.message
      );
      //Mensaje de error de que no hay sectores creados
    }

    this.load = false;
  },
  methods: {
     reset() {
            this.$store.state.meta_data_store = [];
            this.$store.state.meta_data_store.push({id:Date.parse(new Date())+Math.random() , name: '', type: 'string', key: '', value: '' });
            this.nombre_documento = "";
            this.fecha_limite = null;
            this.$refs.form.resetValidation();
        },
        async crear_endpoint_almacenamiento() {
            let fields = [];

            this.$store.commit("auth");
            console.log(fields);
            var str;
            var res;
            for (let index = 0; index < this.$store.state.meta_data_store.length; index++) {
                console.log(index);
                str = this.$store.state.meta_data_store[index].name;
                res = str.replace(/ /g, "_");
                this.$store.state.meta_data_store[index].key = res
                this.$store.state.meta_data_store[index].value = ""
                console.log(res);
                fields.push({ name: res, type: "string", "facet": true });
                if(res.toLowerCase()=='fecha'){
                   fields.push({ name: 'fecha_documento_int', type: "int64", "facet": false });
                }
            }
            //fields.push({ name: "nombre_documento", type: "string" });
            fields.push({ name: "fecha_creacion_int", type: "int64" }); // Tendra el valor de Date.parse(new Date()) de la fecha donde se esta mandando al indexador
            fields.push({name: "order", type: "int64"});
            fields.push({name:"extended_properties", type: "string"});
            fields.push({name:"estatus", type: "string",facet: true});
            //fields.push({name:"autor", type: "string",facet: true});
            
            fields.push({name:"relacionado_con", type: "string",facet: true}); //Se coloca el ID de otra file con el que tenga relación la query
            //fields.push({name:"observaciones", type: "string",facet: true});

            let name = this.nombre_documento.replace(/ /g, "");
            
            
            //fields.push({ name: "datos_archivos", type: "string[]" }); 
            
            
            //Contendra un array de objetos formato json
            //Cuya estrcutura sera: {token, nombre, tipo,autor,sector, area_id, fecha_creacion } 
            //Token tendra la carpeta de upload.php nombre sera el nombre...
            //un numero preferiblemente y tipo sera el formato del archivo png, blob pdf etc etc
            
            
            //fields.push({ name: "carpetas", type: "string[]" }); 
            
            
            // los distintos tipos de "carpetas" según se vayan necesitando. Cada indice indicará
            //un tipo distinto de expediente su estructura 
            //será { nombre:"exp1" meta_datos: [{nombre, value}] archivos:[ nombre,token,tipo,autor,sector,fecha_creacion, meta_datos ]}
            
            console.log(name);
            console.log(fields);
            const name_endpoint = "V1_" + name;
            try {
                var response5 = await axios.post(
                "https://siett.educandote.co/types/collections/",
                {
                    name: name_endpoint,
                    fields: fields,
                    default_sorting_field: "order",
                },
                {
                    headers: {
                    "Content-Type": "application/json",
                    "X-TYPESENSE-API-KEY":
                        "S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe",
                    },
                });

                   
                console.log(response5);
                let create_endpoint_images = await this.$store.dispatch('crear_endpoint_archivos', name_endpoint )

                
                console.log(create_endpoint_images);
                if(create_endpoint_images){
                  return true;
                }
                
             
                //Creamos el endpoint que almacenará las imagenes asociadas a la coleccion creada anteriormente

                
            } catch (error) {
                console.log(error);
                

               
                //Aqui habria que crear una promesa segun sea el error para que retorne a la otra funcion
            }
             return false;
          },
            async crear() {
            console.log("creando");
            try {
                let respuesta = await this.crear_endpoint_almacenamiento(); //Creamos el endpoint en el typesense
                if (respuesta) {
                this.$store.commit("auth", "parse");
                let name = "V1_" + this.nombre_documento.replace(/ /g, ""); //Se quita esto de prueba

                //Hay que hacer una validacion de que no exitan dos documentos con el mismo nombre
                //Pero mas adelante jeje (aunque no he hecho la prueba si se colcan 2 con el mismo nombre explota el otro y no se crea respuesta)

                let response = await axios.post(
                    "https://siett.educandote.co/parse/classes/V1_tiposDocumentales",
                    {
                    nombre: this.nombre_documento,
                    nombre_endpoint_almacenamiento: name, //Este parametro sirve para: Resulta que
                    //Aparte de este backend tenemos un indexador que se encarga de almacenar grandes cantidades de documentos
                    //Legales y segun el documento legal se crea un endpoint especifico para su respectivo almacenamiento
                    //Para de esta manera facilitar su busqueda cuando haya grandes cantidades de informacion
                    //Este campo NO PUEDE SER MODIFICADO!!!!!!!! puesto que es la llave para la consulta y almacenamiento
                    parametros: this.$store.state.meta_data_store,
                    fecha_limite: this.fecha_limite,
                    },
                    {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                        "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                        Authorization: this.$store.state.header,
                    },
                    }
                );
               
                let obj = {
                    fecha_limite: this.fecha_limite,
                    nombre: this.nombre_documento,
                    parametros: this.$store.state.meta_data_store,
                    objectId: response.data.objectId,
                };
                  this.items.push(obj)

                this.$store.commit(
                    "exitoso",
                    "El tipo documental: " +
                    this.nombre_documento +
                    " ha sido creado exitosamente"
                );

                console.log(response);
                } else {
                this.$store.commit(
                    "error",
                    "Algo ha salido mal creando la biblioteca de almacenamiento para el tipo documental : " +
                    this.nombre_documento +
                    " por favor intente mas tarde "
                );
                }
            } catch (error) {
                console.log(error);
                this.$store.commit(
                "error",
                "Algo ha salido mal creando el tipo documental: " +
                    this.nombre_documento +
                    " por favor intente mas tarde "+error.message
                );
                //Mensaje de error de que no hay sectores creados
            }

            this.reset();
            },
            async editar() {
            console.log(this.objectId);
            console.log(this.nombre_documento);
            console.log(this.$store.state.meta_data_store);
            try {
                this.$store.commit("auth", "parse");
                let response = await axios.put(
                "https://siett.educandote.co/parse/classes/V1_tiposDocumentales/" +
                    this.objectId,
                {
                    nombre: this.nombre_documento,
                    parametros: this.$store.state.meta_data_store,
                },
                {
                    headers: {
                    "Content-Type": "application/json",
                    "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
                    "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
                    Authorization: this.$store.state.header,
                    },
                }
                );
                console.log(response);
                this.items[this.index].fecha_limite = this.fecha_limite;
                 Object.assign(this.items[this.index],{nombre: this.nombre_documento, parametros: this.$store.state.meta_data_store, objectId: this.objectId })
               
                /*
                this.items[this.index].nombre = this.nombre_documento;
                this.items[this.index].parametros = this.meta_data;
                this.items[this.index].objectId = this.objectId;
                */
                this.$store.commit(
                "exitoso",
                "El tipo documental: " +
                    this.nombre_documento +
                    " ha sido actualizado de manera exitosa"
                );
            } catch (error) {
                console.log(error);
                this.$store.commit(
                "error",
                "Algo ha salido mal editando el tipo documental: " +
                    this.nombre_documento +
                    ", por favor intente mas tarde "+error.message
                );
                //Mensaje de error de que no hay sectores creados
            }
                this.reset();
                this.boton_crear = true;
            },
    
    
  
    edit(evento, index) {
      console.log(evento);

      //Se asignan los valores base a editar y hacemos un movimiento del scroll

      this.nombre_documento = evento.nombre;
      for (let index = 0; index < evento.parametros.length; index++) {
        evento.parametros[index].id = Date.parse(new Date())+index 
        
      }
      this.$store.state.meta_data_store = evento.parametros;
      this.objectId = evento.objectId;
      this.fecha_limite = evento.fecha_limite;
      this.$vuetify.goTo(this.target, this.options);
      this.boton_crear = false;
      this.index = index;
      console.log("editando");
    },
    eliminar(documento, index) {
      console.log(documento);
      this.objectId = documento.objectId;
      this.nombre_documento_eliminar = documento.nombre;
      this.index = index;

      this.dialogEliminar = true;
      console.log("eliminar");
    },
    async eliminacion_confirmada() {
      console.log("Eliminando");
      console.log(this.objectId);
      this.dialogEliminar = false;
      try {
        this.$store.commit("auth", "parse");
        let response = await axios.delete(
          "https://siett.educandote.co/parse/classes/V1_tiposDocumentales/" +
            this.objectId,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Parse-REST-API-Key": "LASDK823JKHR87SDFJSDHF8DFHASFDF",
              "X-Parse-Application-Id": "KSDJFKASJFI3S8DSJFDH",
              Authorization: this.$store.state.header,
            },
          }
        );
        this.items.splice(this.index, 1);
        this.$store.commit(
          "exitoso",
          "el tipo documental: " +
            this.nombre_documento_eliminar +
            ", ha sido eliminado exitosamente"
        );
        console.log(response);
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Algo ha salido mal eliminando el tipo documental: " +
            this.nombre_documento_eliminar +
            ", por favor intente mas tarde "+error.message
        );
      }
    },
    revisar() {
      console.log("revisar");
    },
  },
};
</script>