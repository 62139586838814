<template>
    <v-text-field
        :rules="[
        (v) => !!v || 'Es requerido asignar un nombre al campo',
        ]"
        required
        v-model="$store.state.meta_data_store[index].name"
        rounded
        dense
        filled
        prepend-icon="mdi-book"
        label="Nombre campo"
        :append-outer-icon="'mdi-delete'"
        @click:append-outer="$store.state.meta_data_store.splice(index, 1)"
    ></v-text-field>
    
</template>
<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        }
    }
}
</script>